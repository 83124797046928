function getUser() {
    let user = JSON.parse(localStorage.getItem('user'))
    if(user)
        return user;
    return null;
}

function getOperator() {
    let operator = JSON.parse(localStorage.getItem('operator'))
    if(operator)
        return operator
    return null
}

function getToken() {
    let token = JSON.parse(localStorage.getItem('token'))
    if(token)
        return token;
    return null;
}

function getTokenTimestamp() {
    let tokenTimestamp = JSON.parse(localStorage.getItem('tokenTimestamp'))
    if(tokenTimestamp)
        return tokenTimestamp;
    return null;
}

function logout(socket=null,query=null) {
    localStorage.clear()

    if(socket) {
        socket.disconnect()
    }

    if(query) {
        // console.log(query)
        window.location.replace(`/#/login?${query}`)
    } else {
        window.location.reload()
    }
    // window.location.replace('/#/login')
}

module.exports = {
    getUser, logout, getToken, getTokenTimestamp, getOperator
}