<template>
  <div>
    <b-overlay class="overlay-app" :show="overlay" rounded="sm">
      <Alert :msg="msg"/>
      <div>
        <b-container class="p-0" fluid v-if="chatIframe">
          <router-view  @msg="sendAlert" :user="user" :socket="socket" :i18n="i18n" :timestampDiff="timestampDiff" :mediaURL="mediaURL"/>
        </b-container>
        <b-container class="p-0" fluid v-if="messagesIframe">
          <router-view  @msg="sendAlert" :i18n="i18n" :timestampDiff="timestampDiff" :mediaURL="mediaURL"/>
        </b-container>
        <b-container class="p-0 d-flex flex-column" fluid v-else-if="attendance">
          <Headerattendance :fullscreen="fullscreen" @fullscreen="toggleFullscreen()" @operatorscheduling="toggleScheduling()" @msg="sendAlert" :user="user" :socket="socket" :operator="operator" :mediaURL="mediaURL"/>
          <b-row class="contentattendance m-0 px-0" :class="{ 'contentfullscreen':fullscreen, 'p-0':fullscreen }">
            <router-view :operatorscheduling="scheduling" @msg="sendAlert" @operatorscheduling="toggleScheduling()" :user="user" :socket="socket" :operator="operator" :i18n="i18n" :timestampDiff="timestampDiff" :mediaURL="mediaURL"/>
          </b-row>
        </b-container>
        <b-container class="p-0" fluid v-else-if="!login">
          <b-row class="m-0">
            <b-col class="sidebarcol p-0" v-if="!fullscreen">
              <Sidebar :user="user" :sidebarWidth="sidebarWidth" :mediaURL="mediaURL" :socket="socketQR"/>
            </b-col>
            <b-col class="p-0 d-flex flex-column" id="content" :style="{ 'flex-grow:1':fullscreen }">
              <Header @fullscreen="toggleFullscreen()" :socket="socketQR" :fullscreen="fullscreen" @msg="sendAlert" :sidebarWidth="sidebarWidth" :user="user" :mediaURL="mediaURL"/>
              <b-row class="content m-0" :class="{ 'contentfullscreen':fullscreen }">
                <Subheader :subH="subH" :user="user" v-if="!fullscreen" :mediaURL="mediaURL" />
                <router-view @msg="sendAlert" :user="user" :socket="socket" @channelConfig="setChannelConfig" :subheaderPlusHeaderHeight="subheaderPlusHeaderHeight" :i18n="i18n" :timestampDiff="timestampDiff" :mediaURL="mediaURL"/>
                <Subfooter :user="user" :mediaURL="mediaURL" />
              </b-row>
              <div class="footer d-flex flex-column justify-content-end h-100 row-bg">
                <!-- <Footer :user="user"/> -->
              </div>
            </b-col>
          </b-row>
        </b-container>
        <span v-else><router-view @login="setUser" @token="setToken" @msg="sendAlert" :url="url" /></span>
      </div>
      <template #overlay>
        <b-skeleton></b-skeleton>
        <b-spinner></b-spinner>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import Sidebar from './components/sidebar.vue'
import Header from './components/header.vue'
import Headerattendance from './components/headerattendance.vue'
import Subheader from './components/subheader.vue'
// import Subheaderattendance from './components/subheaderattendance.vue'
// import Footer from './components/footer.vue'
import Subfooter from './components/subfooter.vue'
import Alert from './components/alert.vue'
import io from 'socket.io-client'
import { getToken, getTokenTimestamp, getUser, getOperator, logout } from './services/userService.js'
import api from './services/apiService.js'

export default {
  components: {
    Sidebar,Header,Headerattendance,Subheader,Subfooter,
    // Footer,
    Alert,
  },
  created:function() {
    // document.title = 'Gotalk – Plataforma para múltiplos atendentes do WhatsAPP de sua empresa com a API Oficial!'
    setInterval(() => {
      this.setScreenSize()
      this.setSidebarWidth()
      this.setFullscreen()
    },500)
    this.getUserLS()
    this.getTokenLS()
    this.getUrl()
    this.onCloseModal()
    this.fullscreenChange()

    // const isCached = performance.getEntriesByType("navigation")[0].transferSize === 0;
    // console.log({isCached})

    this.$root.$on('attendances_count', (count) => {
      console.log('attendances_count',count, this.user)
      if(this.user) {
        this.user.attendancesCount = count
      }
    })
  },
  data() {
    return {
      attendance: false,
      socket: {},
      socketQR: {},
      login: true,
      chatIframe: false,
      messagesIframe: false,
      fullscreen: false,
      scheduling: false,
      subH: {},
      msg: {},
      overlay: false,
      sidebarWidth: 0,
      user: null,
      token: null,
      operator: {},
      url: '',
      title: 'Gotalk',
      subheaderPlusHeaderHeight: 0,
      timestampDiff: 0,
      interval: 5,
      mediaURL: '',
      i18n: { // emoji categories
        search: 'Pesquisar...',
        categories: {
            Activity: "Atividades",
            Flags: "Bandeiras",
            Foods: "Comida",
            Frequently: "Frequentes",
            Objects: "Objetos",
            Nature: "Natureza",
            Peoples: "Pessoas",
            Symbols: "Símbolos",
            Places: "Locais"
        }
      },
    }
  },
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    fullscreenChange() {
      document.addEventListener('fullscreenchange', () => {
        if (this.screenWidth > 1000 && !document.fullscreenElement) {
          this.fullscreen = false;
        }
      }, { passive: true });
    },
    toggleScheduling() {
      this.scheduling = !this.scheduling
    },
     setScreenSize() {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
    },
    setFullscreen() {
      if(this.screenWidth <= 1000) {
        this.fullscreen = true
      }
    },
    setSidebarWidth() {
      const sidebar = document.querySelector(".sidebarcol")
      if(sidebar) {
        this.sidebarWidth = sidebar.clientWidth
      }
    },
    sendAlert(msg) {
      this.msg = msg
    },
    async setUser(user) {
      this.user = user
      if(user.operator) {
        this.operator = user.operator
        this.user.channelId = this.operator.channelId
        const channel = await api.getChannel(this.operator.channelId)
        if(channel.statusCode == 200)
          this.operator.channel = channel.channel.channel
        const config = await api.getChannelConfig(this.operator.channelId)
        if(config.statusCode == 200)
          this.operator.channelConfig = config.channelConfig
      } else if(['channel','channel_manager'].includes(user.role)) {
        const config = await api.getChannelConfig(user.channelId || user.roleId)
        if(config.statusCode == 200)
          this.$set(this.user, 'channelConfig', config.channelConfig)
          // this.user.channelConfig = config.channelConfig
        const channel = await api.getChannel(user.channelId || user.roleId)
        if(channel.statusCode == 200)
          this.$set(this.user, 'channel', channel.channel.channel)
          // this.user.channel = channel.channel.channel
      }
      // this is here so that the components can see the changes on user
      const aux = this.user.updatedAt
      this.user.updatedAt = Date.now()
      this.user.updatedAt = aux
      
      delete user.operator
      this.getUrl()
    },
    setToken(token) {
      this.token = token
    },
    async getUserLS() { // get user from local storage 
      const user = getUser()
      if(user) {
        this.user = user
        if(user.role == 'operator') {
          const operator = getOperator()
          if(operator) {
            this.operator = operator
            this.user.channelId = this.operator.channelId
          }
          const op = await this.getOperatorData(user)
          if(operator) {
            op.privacy = operator.privacy
          }
          this.operator = op
          const channel = await api.getChannel(this.operator.channelId)
          if(channel.statusCode == 200)
            this.operator.channel = channel.channel.channel
          const config = await api.getChannelConfig(this.operator.channelId)
          if(config.statusCode == 200)
            this.operator.channelConfig = config.channelConfig
        } else if(['channel','channel_manager'].includes(user.role)) {
          const config = await api.getChannelConfig(user.channelId || user.roleId)
          if(config.statusCode == 200)
            this.$set(this.user, 'channelConfig', config.channelConfig)
            // this.user.channelConfig = config.channelConfig
          const channel = await api.getChannel(user.channelId || user.roleId)
          if(channel.statusCode == 200)
            this.$set(this.user, 'channel', channel.channel.channel)
            // this.user.channel = channel.channel.channel

          // this is here so that the components can see the changes on user
          const aux = this.user.updatedAt
          this.user.updatedAt = Date.now()
          this.user.updatedAt = aux
        }
      }
    },
    getTokenLS() { // get token from local storage 
      const token = getToken()
      const tokenTimestamp = getTokenTimestamp()
      // console.log('tokenTimestamp',tokenTimestamp - Date.now() <= -86400000,tokenTimestamp - Date.now())
      if(token) {
        if(!tokenTimestamp || tokenTimestamp - Date.now() <= -86400000)
          logout()
        this.token = token
      }
    }, 
    connect(socket,token) {
      const baseURL = window.location.host.includes('localhost') ? process.env.VUE_APP_BASEURL : `${window.location.protocol}//${window.location.host}`
      socket = io(`${baseURL}:${process.env.VUE_APP_SOCKET_PORT}`, { query: {token} });
      console.log('try connection')
      return socket
    },
    connectQR(socket,token) {
      const baseURL = window.location.host.includes('localhost') ? process.env.VUE_APP_BASEURL : `${window.location.protocol}//${window.location.host}`
      socket = io(`${baseURL}:${process.env.VUE_APP_SOCKET_PORT_QR}`, {query: {token}});
      console.log('try connection QR')
      return socket
    },
    getUrl() {
      let url = document.location.href
      if(url.startsWith("https://www.gotalk") || url.startsWith("http://www.gotalk")) {
        document.location.replace("https://app.gotalk.digital")
      }

      if(url.startsWith("http://") && !url.includes('localhost')) {
        document.location.replace(url.replace('http://','https://'))
      }

      /* 
        If the url includes one of the strings in this array, 
        the title should come from the string before the first '.' found.
        Ex.: gotalk.digital, title = Gotalk

        If the url doesnt satisfy the previous condition, 
        the title comes from the string between the first and second '.' (if there is) found
        Ex.: app.go.com, title = Go
      */
      const titleFromSubdomain = [ 'gotalk', 'gobot', 'localhost', '.agitel' ]

      const regex = /^(https?:\/\/)app([1-9][0-9]{0,9})/;
      const isGotalkApp = regex.test(url) // indicates if it is alt domain from gotalk
      
      if(titleFromSubdomain.find(el => url.includes(el) && !url.includes("//app")))
        url = url.replace('https://','')?.split('.')[0]
      else
        url = url.replace('https://','').split('.')[1]

      this.url = url
      if(!this.isStandard())
        this.title = this.url.charAt(0).toUpperCase() + this.url.slice(1)

      if(this.isStandard() && !this.url.includes('localhost')) {
        if(url == 'dev')
          this.mediaURL = "https://storagedev.gotalk.digital/"
        else if(this.user?.urlStorage)
          this.mediaURL = this.user.urlStorage
        else if(isGotalkApp)
          this.mediaURL = `https://storage${document.location.host}/`
        else
          this.mediaURL = `https://storage.${document.location.host}/`
      } else {
        if(this.user?.urlStorage)
          this.mediaURL = this.user.urlStorage
        else if(this.user?.urlServer && this.user?.urlServer.startsWith("https://app")) // this else if needs to be removed later on
          this.mediaURL = this.user.urlServer.replace("https://app","https://storageapp")
        else
          this.mediaURL = "https://storage.gotalk.digital/"
      }
      if(this.mediaURL && this.mediaURL.at(-1) != '/') {
        this.mediaURL = this.mediaURL.concat('/')
      }
    },
    isStandard() {
      if(this.url == 'gotalk' || this.url.includes('localhost') || document.location.host.includes('dev.gotalk')) {
        return true
      }
    },
    onCloseModal() {
      this.$root.$on('bv::modal::hide', () => {
      })
    },
    intervalSet() {
      const si = setInterval(() => {
        if(this.interval > 0) this.interval--
      }, 1000)
      setTimeout(() => {
        clearInterval(si)
      }, 10000)
    },
    async getOperatorData(user = null) {
      if(!user) user = this.user
      const resp = await api.getOperatorData(user.roleId)
      return resp.operator
    },
    setChannelConfig(channelConfig) {
      this.user.updatedAt = new Date(Date.now()).toISOString()
      this.user.channelConfig = channelConfig
    },
    // getSubheaderPlusHeaderHeight() {
    //   setTimeout(() => {
    //     const header = document.querySelector('header')
    //     const subheader = document.querySelector('.subheader')
    //     if(subheader && header)
    //       this.subheaderPlusHeaderHeight = subheader.offsetHeight + header.offsetHeight
    //     else
    //       this.getSubheaderPlusHeaderHeight()
    //   }, 1000)
    // },
    getSubheaderPlusHeaderHeight() {
      const getHeights = () => {
        const header = document.querySelector('header')
        const subheader = document.querySelector('.subheader')
        if (header && subheader) {
          this.subheaderPlusHeaderHeight = subheader.offsetHeight + header.offsetHeight
        } else {
          requestAnimationFrame(getHeights)
        }
      }
      requestAnimationFrame(getHeights)
    },
    getServerTimestamp() {
      this.socket.emit('get_server_timestamp', { id: this.user.roleId, type: this.user.role })
      this.socket.on('get_server_timestamp', (data) => {
        if(data.date)
          this.timestampDiff = Math.abs(Date.now() - data.date) / 1000
      })
    },
    async getUserData() {
      const channel = await api.getChannel(this.user.channelId)
      if(channel.statusCode == 200)
        this.user.channel = channel.channel.channel

      const config = await api.getChannelConfig(this.user.channelId)
      if(config.statusCode == 200)
        this.user.channelConfig = config.channelConfig

      this.user.operatorData = await this.getOperatorData(this.user)
      localStorage.setItem('user', JSON.stringify(this.user))
    },
    async handleIframe() {
      this.login = false
      this.attendance = false
      this.chatIframe = true

      let token

      const tokenAdmin = this.$route.query?.token,
        operatorId = this.$route.query?.operatorId

      if (tokenAdmin && operatorId) {
        const resp = await api.getOperatorToken(tokenAdmin, operatorId)
        if(resp.statusCode == 200) {
          token = resp.token
        }
        if(token) {
          localStorage.setItem('token', JSON.stringify(token))
          localStorage.setItem('tokenTimestamp', JSON.stringify(Date.now()))
        }
      } else if (tokenAdmin) {
        localStorage.setItem('token', JSON.stringify(tokenAdmin))
        localStorage.setItem('tokenTimestamp', JSON.stringify(Date.now()))
      }

      const user = {
        channelId: this.$route.query?.channelId,
        operatorId,
        roleId: operatorId,
        attendanceId: this.$route.query?.attendanceId,
        role: 'operator',
        type: 1
      }

      if (token) {
        this.socket = this.connect(this.socket,token)
        this.socket.on('connect', () => {
          console.log('connect')
          this.getServerTimestamp()
        })
      }

      this.user = user
      
      if (user.operatorId)
        this.getUserData()
    },
    async checkEnableQR() {
      if (this.user?.channelConfig?.enableQRThroughChannel)
        return true
      
      const secondaryChannels = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)?.channels

      if (secondaryChannels?.length) {
        for (const channel of this.secondaryChannels) {
          const config = await api.getChannelConfig(channel._id)
          this.$set(channel,'channelConfig',config.channelConfig)
        }
        return this.secondaryChannels.some(el => el.channelConfig?.enableQRThroughChannel)
      }
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        if(this.checkEnableQR()) {
          if(!this.socketQR?.active)
            this.socketQR = this.connectQR(this.socketQR, this.token || getToken())

          this.socketQR.on('connect', () => {
            console.log('connectQR')
          })

          this.socketQR.on("connect_error", (err) => {
            console.log(`connect_error QR due to ${err.message}`);
          })

          this.socketQR.on('disconnect',() => {
              console.log('disconnect QR')
            })
        }
      }
    },
    $route(to) {
      switch(to.name) {        
        case 'Home':
          if(this.user && this.user.role == 'admin') {
            document.title = `${this.title} - Canais`
            this.subH.title = 'Canais'
            this.subH.subtitle = 'Gerenciar canais'
            this.subH.icon = 'collection'
            break;
          } else if(this.user && this.user.role == 'operator') {
            document.title = `${this.title} - Painel Atendimento`
          } else {
            document.title = `${this.title} - Painel Dashboard`
            this.subH.title = 'Painel Dashboard'
            this.subH.subtitle = 'Painel Dashboard'
            this.subH.icon = 'house'
          }
          break;
        case 'AttendancesManage':
          document.title = `${this.title} - Atendimentos`
          this.subH.title = 'Atendimentos'
          this.subH.subtitle = 'Atendimentos'
          this.subH.icon ='chat-left-text'
          break;
        case 'Contacts':
          document.title = `${this.title} - Contatos`
          this.subH.title = 'Contatos'
          this.subH.subtitle = 'Contatos'
          this.subH.icon ='person'
          break;
        case 'Clients':
          document.title = `${this.title} - Clientes`
          this.subH.title = 'Clientes'
          this.subH.subtitle = 'Clientes'
          this.subH.icon = 'people'
          break;
        case 'Channels':
          document.title = `${this.title} - Canais`
          this.subH.title = 'Canais'
          this.subH.subtitle = 'Gerenciar canais'
          this.subH.icon = 'collection'
          break;
        case 'QR':
          document.title = `${this.title} - QR`
          this.subH.title = 'QR'
          this.subH.subtitle = 'Leitura de QR'
          this.subH.icon = 'server'
          break;
        case 'ReportAttendance':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - Atendimento'
          this.subH.icon = 'clipboard-data'
          break;
        case 'ReportAverage':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - TME/TMA'
          this.subH.icon = 'clipboard-data'
          break;
        case 'ReportTabulation':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - Tabulações'
          this.subH.icon = 'clipboard-data'
          break;
        case 'ReportSurvey':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - Pesquisa de Satisfação'
          this.subH.icon = 'clipboard-data'
          break;
        case 'ReportStats':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - Estatística'
          this.subH.icon = 'clipboard-data'
          break;
        case 'ReportAccessLog':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - Logs de Acesso'
          this.subH.icon = 'clipboard-data'
          break;
        case 'ReportGupshupTraffic':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - Tráfego'
          this.subH.icon = 'clipboard-data'
          break;
        case 'ReportKanban':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - Kanban'
          this.subH.icon = 'kanban'
          break;
        case 'ReportKanbanPerformance':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - Desempenho'
          this.subH.icon = 'kanban'
          break;
        case 'ReportCampaign':
          document.title = `${this.title} - Relatórios`
          this.subH.title = 'Relatórios'
          this.subH.subtitle = 'Relatórios - Campanha'
          this.subH.icon = 'megaphone'
          break;
        case 'ManageChannel':
          document.title = `${this.title} - Gerenciar Canal`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Canal'
          this.subH.icon = 'folder'
          break;
        case 'ManageCampaigns':
          document.title = `${this.title} - Gerenciar Campanhas`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Campanhas'
          this.subH.icon = 'folder'
          break;
        case 'ManageCampaign':
          document.title = `${this.title} - Gerenciar Campanha`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Campanha'
          this.subH.icon = 'folder'
          break;
        case 'ManageQuickAnswers':
          document.title = `${this.title} - Gerenciar Respostas Rápidas`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Respostas Rápidas'
          this.subH.icon = 'folder'
          break;
        case 'ManageList':
          document.title = `${this.title} - Gerenciar Lista Interativa`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Lista Interativa'
          this.subH.icon = 'folder'
          break;
        case 'ManageButton':
          document.title = `${this.title} - Gerenciar Botão Interativo`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Botão Interativo'
          this.subH.icon = 'folder'
          break;
        case 'ManageChatbot':
          document.title = `${this.title} - Gerenciar ChatBot`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - ChatBot'
          this.subH.icon = 'folder'
          break;
        case 'Managemenu':
        case 'ManageMenuCustom':
          document.title = `${this.title} - Gerenciar ChatBot`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - ChatBot'
          this.subH.icon = 'folder'
          break;
        case 'Managemessages':
          document.title = `${this.title} - Gerenciar Mensagens`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Mensagens'
          this.subH.icon = 'folder'
          break;
        case 'Manageoperators':
          document.title = `${this.title} - Gerenciar Operadores`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Operadores'
          this.subH.icon = 'folder'
          break;
        case 'ManageDepartments':
          document.title = `${this.title} - Gerenciar Departamentos`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Departamentos'
          this.subH.icon = 'folder'
          break;
        case 'ManageHSMModels':
        case 'ManageHSMModelsOld':
          document.title = `${this.title} - Gerenciar Templates HSM`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Templates HSM'
          this.subH.icon = 'folder'
          break;
        case 'TemplateHSM':
          document.title = `${this.title} - Gerenciar Template HSM`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Template HSM'
          this.subH.icon = 'folder'
          break;
        case 'ManageSupervisors':
          document.title = `${this.title} - Gerenciar Supervisores`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Supervisores'
          this.subH.icon = 'folder'
          break;
        case 'Managegroups':
          document.title = `${this.title} - Gerenciar Grupos`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Grupos'
          this.subH.icon = 'folder'
          break;
        case 'Managetags':
          document.title = `${this.title} - Gerenciar Etiquetas`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Etiquetas'
          this.subH.icon = 'folder'
          break;
        case 'ManageTabulations':
          document.title = `${this.title} - Gerenciar Tabulações`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Tabulações'
          this.subH.icon = 'folder'
          break;
        case 'ManageKanban':
        case 'ManageKanbans':
          document.title = `${this.title} - Gerenciar Kanban`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Kanban'
          this.subH.icon = 'kanban'
          break;
        case 'ManageModules':
          document.title = `${this.title} - Gerenciar Módulos`
          this.subH.title = 'Gerenciar'
          this.subH.subtitle = 'Gerenciar - Módulos'
          this.subH.icon = 'folder'
          break;
        case 'ProductsList':
          document.title = `${this.title} - Produtos`
          this.subH.title = 'Produtos'
          this.subH.subtitle = 'Produtos - Lista de Produtos'
          this.subH.icon = 'bag-dash'
          break;
        case 'Groups':
          document.title = `${this.title} - Grupos`
          this.subH.title = 'Grupos'
          this.subH.subtitle = 'Grupos'
          this.subH.icon = 'people'
          break;
        case 'Attendance':
          document.title = `${this.title} - Painel de Atendimento`
          // this.subH.title = 'Painel de Atendimento'
          // this.subH.subtitle = 'Painel de Atendimento'
          // this.subH.icon = 'file-person'
          break;
        case 'Admin':
          // document.title = 'Gotalk - Painel de Atendimento'
          this.subH.title = 'Admin'
          this.subH.subtitle = 'Gerenciar'
          this.subH.icon ='person-fill'
          break;
        case 'API':
          document.title = `${this.title} - API`
          this.subH.title = `${this.title} - API`
          this.subH.subtitle = 'API'
          this.subH.icon ='code-slash'
          break;
        default:
          document.title = `${this.title} – Plataforma para múltiplos atendentes do WhatsAPP de sua empresa com a API Oficial!`
      }

      if(to.name == "Groups")
      this.getSubheaderPlusHeaderHeight()
    
    if(to.name == 'Login') {
      this.reconnectAttempts = 0
      this.login = true
      this.attendance = false
      this.chatIframe = false
    } else if(to.name == 'Attendance' || (to.name == 'Home' && this.user?.role == 'operator') ) {
        this.attendance = true
        this.login = false
        this.chatIframe = false
        const token = this.token || getToken()
        if(token) {
          this.socket = this.connect(this.socket,token)
          
          this.socket.on('connect', () => {
            console.log('connect')
            this.getServerTimestamp()
            // let a = []
            // if(JSON.parse(localStorage.getItem('msgs')))
            //   a = JSON.parse(localStorage.getItem('msgs'));
            // if(a.length) {
              //   for (let pos in a) {
                //     this.socket.emit('send_message',a[pos])
                //     a = a.filter(function(el) { return el != a[pos]; })
                //     localStorage.setItem('msgs', JSON.stringify(a));
                //   }
                // }
              })
              
              this.socket.on('operator_data',(data) => {
                // console.log('operator_data',data)
                if(data.operator) {
                  this.operator.privacy = data.privacy
                  this.operator.status = data.operator.status
            }
            else {
              this.operator.status = data.status
            } 
          })
          this.socket.on('outside_working_hours',() => {
            console.log('outside_working_hours')
            logout(this.socket,'logout=outside_working_hours')
          })
          this.socket.on('disconnected_operator',() => {
            console.log('disconnected_operator')
            // this.$bvToast.toast(`Seu usuário logou em outro local!`, {
            //   title: 'Desconectado',
            //   appendToast: false,
            //   noAutoHide: true,
            //   solid:true,
            //   variant: 'danger',
            // })
            logout(this.socket,'logout=disconnected_operator')
            // logout(this.socket,`t=${JSON.parse(localStorage.getItem('user')).loginToken}`)
          })
          this.socket.on('already_connected',() => {
            console.log('already_connected')
            this.$refs['modal-alreadyConnected'].show()
          })
          this.socket.on('disconnect',() => {
            console.log('disconnect')
            // let user = this.user
            // if(user) {
              //   // this.$bvToast.show('reconnect')
              //   this.intervalSet()
              //   setTimeout(() => {
                //     if(!this.socket.active) {
                  //       const token = this.token || getToken()
                  //       this.socket = this.connect(this.socket,token)
                  //     }
                  
                  //     // if(this.socket.active) {
                    //       // this.reconnectAttempt.variant = 'success'
                    //       // this.reconnectAttempt.msg = 'Conectado!'
                    //       // this.$bvToast.show('reconnectAttempt')
                    //     // } else {
                      //       // this.reconnectAttempt.variant = 'danger'
                      //       // this.reconnectAttempt.msg = 'Erro ao conectar!'
                      //       // this.$bvToast.show('reconnectAttempt')
                      //     // }
                      //   },this.interval*1000)
                      // }
          })
        } else {
          logout(this.socket)
        }
      } else if(to.name == 'QR') {
        this.login = false
        this.attendance = false
        const token = this.token || getToken()
        this.socket = this.connectQR(this.socket, token)
        this.socket.on('connect', () => {
          console.log('connect QR')
        })
        this.socket.on("connect_error", (err) => {
          console.log('connect_error QR',err)
        })
      } else if(to.name == "ChatIframe") {
        this.$nextTick(this.handleIframe)
      } else if(to.name == "MessagesIframe") {
        this.messagesIframe = true
      } else {
        this.login = false
        this.attendance = false
        if(!this.socket?.active) {
          const token = this.token || getToken()
          if(token) {
            this.socket = this.connect(this.socket, token)
            // console.log('token user', this.socket)
            
            this.socket.on('connect', () => {
              console.log('connect')
              this.getServerTimestamp()
            })
            
            this.socket.on("connect_error", (err) => {
              console.log(`connect_error due to ${err.message}`);
              const user = this.user
              if(user) {
                this.reconnectAttempts++
                // this.$bvToast.show('reconnect')
                this.intervalSet()
                if(this.reconnectAttempts<5) {
                  setTimeout(() => {
                    this.socket = this.connect(this.socket,token)
                    // if(this.socket.active) {
                    //   this.reconnectAttempt.variant = 'success'
                    //   this.reconnectAttempt.msg = 'Conectado!'
                    //   this.$bvToast.show('reconnectAttempt')
                    // } else {
                    //   this.reconnectAttempt.variant = 'danger'
                    //   this.reconnectAttempt.msg = 'Erro ao conectar!'
                    //   this.$bvToast.show('reconnectAttempt')
                    // }
                  },this.interval*1000)
                }
              } else {
                logout(this.socket)
              }
              if(this.reconnectAttempts>4) {
                logout(this.socket)
              }
            })
  
            this.socket.on('disconnect',() => {
              console.log('disconnect')
            })
          }
        }
      }
    }
  }
}
</script>

<style>
  /* country flags */
  @import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css");

  .btn:hover{
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0) !important;
  }
  .tooltip-inner{
    font-size: 1em;
    padding: 6px 12px;
  }
  .tooltip{
    opacity: 1 !important;
    padding: .25em 0 !important;
  }
  .tooltip-edit > .tooltip-inner {
    background-color: hsl(94deg 44% 65%);
    color: #fff;
  }
  .tooltip-remove > .tooltip-inner {
    background-color: hsl(360deg 83% 69%);
    color: #fff;
  }
  .tooltip-purple > .tooltip-inner {
    background-color: hsl(242deg 21% 44%) !important;
    color: #fff;
  }
  .tooltip-green > .tooltip-inner {
    background-color: hsl(87deg 55% 65%) !important;
    color: #fff;
  }
  .tooltip-text-white > .tooltip-inner {
    color: #fff !important;
  }
  .b-overlay .overlay-app div:last-child{
    top: 1vh !important;
    width: 100%;
  }
  .b-overlay .overlay-app .spinner-border{
    position: absolute;
    top: 50vh !important;
    left: 50vw !important;
    color: #333;
  }
  .b-overlay .overlay-app .b-skeleton{
    background-color: #333;
  }
  .b-overlay .overlay-app .b-skeleton-text{
    border-radius: 0;
  }
  .page-item {
    padding: 0 0.25em;
  }
  .page-item.active .page-link{
    background-color: hsl(250deg 36% 74%) !important;
    color: #fff !important;
  }
  .page-link{
    color: #6c757d !important;
    border: none !important;
    border-radius: 50% !important;
  }
  .page-link:hover{
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
  }
  .bg-purple{
    --bs-bg-opacity: 1;
    background-color: rgba(90, 89, 135, var(--bs-bg-opacity)) !important;
  }
  .bg-light-purple{
    background-color: hsl(244deg 62% 95%) !important;
  }
  .bg-light-purple2{
    background-color: rgb(213, 209, 237) !important;
  }
  .bg-light-purple3{
    background-color: hsl(248deg 23% 57%) !important;
  }
  .bg-pink{
    background-color: hsl(285deg 61% 70%) !important;
  }
  .bg-green{
    background-color: hsl(87deg 55% 65%) !important;
  }
  .bg-green2{
    background-color: hsl(168.71deg 36.48% 54.31%) !important;
  }
  .bg-green3 {
    background-color: hsl(142deg 38% 59%) !important;
  }
  .bg-orange {
    background-color: hsl(12deg 89% 61%) !important;
  }
  .bg-red {
    background-color: hsl(10deg 79% 63%) !important;
  }
  .bg-yellow {
    background-color: hsl(42deg 100% 50%) !important;
  }
  .bg-grey {
    background-color: hsl(240deg 30% 91%) !important;
  }
  .bg-grey-2 {
    background-color: hsl(0deg 0% 91%) !important;
  }
  .bg-light-grey {
    background-color: hsl(248deg 50% 97%) !important;
  }
  .bg-disabled {
    background-color: hsl(210deg 16% 93%) !important;
  }
  .bg-silver {
    background-color: #bdc3c7 !important;
  }
  .bg-silver-fade {
    background-color: rgba(189, 195, 199, .6) !important;
  }
  .bg-999 {
    background-color: #999;
  }
  .bg-fromMe {
    background-color: hsl(94deg 78% 87%) !important;
  }
  .bg-notFromMe {
    background-color: #fff !important;
  }
  .bg-msgInput {
    background-color: hsl(251deg 49% 93%) !important;
  }
  .bg-transparent2, .bg-transparent2:hover{
    border-color: transparent !important;
    background-image: none !important;
    background-color: transparent !important;
  }
  .row-bg{
    background-color: hsl(230deg 30% 96%);
  }
  .btn-pink{
    --bs-btn-color: #fff;
    --bs-btn-bg: hsl(285deg 61% 70%) !important;
    --bs-btn-border-color: hsl(285deg 61% 70%);
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: hsl(285deg 61% 70%);
    --bs-btn-hover-border-color: hsl(285deg 61% 70%);
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: hsl(285, 64%, 61%);
    --bs-btn-active-border-color: hsl(285, 64%, 61%);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: hsl(285, 46%, 74%);
    --bs-btn-disabled-border-color: hsl(285, 46%, 74%);
  }
  .btn-red{
    --bs-btn-color: #fff;
    --bs-btn-bg: hsl(0deg 84% 69%) !important;
    --bs-btn-border-color: hsl(0deg 84% 69%);
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: hsl(0deg 84% 69%);
    --bs-btn-hover-border-color: hsl(0deg 84% 69%);
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: hsl(0deg 80% 69%);
    --bs-btn-active-border-color: hsl(0deg 70% 69%);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: hsl(0deg 60% 69%);
    --bs-btn-disabled-border-color: hsl(0deg 60% 69%);
  }
  .btn-purple{
    --bs-btn-color: #fff !important;
    --bs-btn-bg: hsl(242deg 21% 44%) !important;
    --bs-btn-border-color: hsl(242deg 21% 44%);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: hsl(242deg 21% 44%);
    --bs-btn-hover-border-color: hsl(242deg 21% 44%);
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: hsl(242deg 15% 44%);
    --bs-btn-active-border-color: hsl(242deg 12% 44%);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: hsl(242deg 5% 40%);
    --bs-btn-disabled-border-color: hsl(242deg 5% 40%);
  }
  .btn-green {
    --bs-btn-color: #fff;
    --bs-btn-bg: hsl(87deg 55% 65%) !important;
    --bs-btn-border-color: hsl(87deg 55% 65%);
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: hsl(87deg 50% 65%);
    --bs-btn-hover-border-color: hsl(87deg 50% 65%);
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: hsl(87deg 40% 65%);
    --bs-btn-active-border-color: hsl(87deg 30% 65%);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: hsl(87deg 20% 65%);
    --bs-btn-disabled-border-color: hsl(87deg 20% 65%);
  }
  .btn-green3 {
    --bs-btn-color: #fff;
    --bs-btn-bg: hsl(142deg 38% 59%) !important;
    --bs-btn-border-color: hsl(142deg 38% 59%);
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: hsl(142deg 38% 50%);
    --bs-btn-hover-border-color: hsl(142deg 38% 50%);
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: hsl(87deg 40% 65%);
    --bs-btn-active-border-color: hsl(87deg 30% 65%);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: hsl(87deg 20% 65%);
    --bs-btn-disabled-border-color: hsl(87deg 20% 65%);
  }
  .text-green {
    color: hsl(87deg 55% 65%) !important;
  }
  .text-green input[type='radio'] {
    accent-color: green;
  }
  .text-purple {
    color: #6b668c !important;
  }
  .text-light-purple {
    color: #918abd !important;
  }
  .text-light-purple3 {
    color: hsl(248deg 23% 57%) !important;
  }
  .text-dark-purple {
    color: #504b75 !important;
  }
  .text-pink {
    color: hsl(285deg 61% 70%) !important
  }
  .text-red {
    color: hsl(10deg 79% 63%) !important
  }
  .text-red input[type='radio'] {
    accent-color: red;
  }
  .text-shadow {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)) !important;
  }
  .text-shadow-sm {
    filter: drop-shadow(2px 2px 1px rgb(0 0 0 / 0.2)) !important;
  }
  .scale-1-3 {
    -ms-transform: scale(1.3); /* IE 9 */
    -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
    transform: scale(1.3);
  }
  .scale-1-5 {
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  .input-scale-1-3 input {
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-scale-1-5 input {
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  .stroke-white {
    stroke: white !important;
  }
  .stroke-black {
    stroke: black !important;
  }
  .border-grey {
    border-color: #eee !important;
  }
  .border-grey2{
    border: 1px solid #eee !important;
  }
  .border-green {
    border: 1px solid hsl(87deg 55% 65%) !important;
  }
  .border-green2 {
    border: 1px solid hsl(168.71deg 36.48% 54.31%) !important;
  }
  .border-green3 {
    border: 1px solid hsl(142deg 38% 59%) !important;
  }
  .border-light-purple {
    border: 1px solid hsl(244deg 62% 95%) !important;
  }
  .border-3-light-purple {
    border: 3px solid hsl(244deg 62% 95%) !important;
  }
  .border-4-light-purple {
    border: 4px solid hsl(244deg 62% 95%) !important;
  }
  .border-dark-grey {
    border-color: #ced4da !important;
  }
  .border-top-grey {
    border-top: .4px solid !important;
    border-color: #eee !important;
  }
  .border-bottom-grey {
    border-bottom: .4px solid !important;
    border-color: #eee !important;
  }
  .border-left-green {
    border-left: 4px solid;
    border-color: hsl(87deg 41% 57%) !important;
  }
  .border-left-purple {
    border-left: 4px solid;
    border-color: hsl(242deg 21% 44%) !important;
  }
  .rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .small-text {
    font-size: 0.9em !important;
  }
  .smaller-text {
    font-size: 0.8em !important;
  }
  .darkenOnHover:hover {
    background-image: linear-gradient(rgba(39, 39, 39, 0.1) 0 0) !important;
  }
  .darkenTextOnHover:hover {
    filter: brightness(50%);
  }
  .filter-none {
    filter: none;
  }
  .filter-secondary {
    filter: invert(33%) sepia(0%) saturate(428%) hue-rotate(192deg) brightness(96%) contrast(89%);
  }
  .me-6 {
    margin-right: 6em !important;
  }
  .me-6-2 {
    margin-right: 8em !important;
  }
  .overflow-y-auto {
    overflow-y: auto;
  }
  .overflow-y-hidden {
    overflow-y: hidden;
  }
  .overflow-x-auto {
    overflow-x: auto;
  }
  .overflow-x-hidden {
    overflow-x: hidden;
  }
  .flex-basis-content {
    flex-basis: content !important;
  }
  .lh-085 {
    line-height: 0.85em;
  }
  .lh-1 {
    line-height: 1;
  }
  .lh-2 {
    line-height: 2;
  }
  .w-max{
    width: max-content !important;
  }
  .w-min{
    width: min-content !important;
  }
  .w-fit{
    width: fit-content !important;
  }
  .w-0 {
    width: 0 !important;
  }
  .w-90{
    width: 90% !important;
  }
  .w-ch1 {
    width: 1ch !important;
  }
  .w-ch2 {
    width: 2ch !important;
  }
  .w-ch3 {
    width: 3ch !important;
  }
  .min-w-ch1 {
    min-width: 1ch !important;
  }
  .min-w-ch2 {
    min-width: 2ch !important;
  }
  .min-w-ch3 {
    min-width: 3ch !important;
  }
  .min-w-ch4 {
    min-width: 4ch !important;
  }
  .min-w-ch5 {
    min-width: 5ch !important;
  }
  .min-w-ch6 {
    min-width: 6ch !important;
  }
  .max-w-min {
    max-width: min-content !important;
  }
  .h-fit{
    height: fit-content !important;
  }
  .mh-75 {
    max-height: 75% !important;
  }
  .mh-50 {
    max-height: 50% !important;
  }
  .mw-75 {
    max-width: 75% !important;
  }
  .mw-50 {
    max-width: 50% !important;
  }
  .min-h-0 {
    min-height: 0 !important;
  }
  .outline-none{
    outline: none !important;
  }
  .py-1-5 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }
  .py-4-5 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .ps-4-5 {
    padding-left: 1.75rem !important;
  }
  .py-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .py-6-5 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-2-5 {
    padding: .75rem;
  }
  .box-clamp-1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    overflow: hidden;
  }
  .border-radius-0 {
    border-radius: 0 !important;
  }
  .opacity-10 {
    opacity: .1;
    -moz-opacity: .4;
    -webkit-opacity: .4;
  }
  .opacity-50 {
    opacity: .50;
    -moz-opacity: .4;
    -webkit-opacity: .4;
  }
  .opacity-75 {
    opacity: .75;
    -moz-opacity: .4;
    -webkit-opacity: .4;
  }
  .z-4 {
    z-index: 4
  }
  .z-5 {
    z-index: 5
  }
  .z-6 {
    z-index: 6
  }
  .manage-btn{
    margin: 0 4px !important;
    padding: 6px !important;
    border: none !important;
    border-radius: 2px !important;
  }
  .modal-btn{
    padding: 5px 20px;
    font-weight: 500;
  }
  .modal-btnsearch{
    background-color: hsl(142deg 38% 59%) !important;
    border-color: hsl(142deg 38% 59%) !important;
    color: #fff !important;
    margin-right: 10px !important;
  }
  .modal-btncancel{
    background-color: #fff !important;
    color: #777 !important;
    border-color: #ccc !important;
  }
  .modal-header .close{
    border: none;
    background-color: transparent;
    font-size: 2em;
    color: #555;
    padding: 0;
  }
  .modal-header .modal-title{
    color: #555;
  }
  .tooltip.b-tooltip-purple .tooltip-inner {
    background-color: hsl(242deg 21% 44%) !important;
  }
  .tooltip.b-tooltip-pink .tooltip-inner {
    background-color: hsl(285deg 61% 70%) !important;
  }
  .tooltip.b-tooltip-red .tooltip-inner {
    background-color: hsl(0deg 84% 69%) !important;
  }
  .tooltip.b-tooltip-green .tooltip-inner {
    color: #fff !important;
    background-color: hsl(87deg 55% 65%) !important;
  }
  .tooltip.b-tooltip-warning .tooltip-inner {
    color: #fff !important;
  }
  .inputKeywords output{
    display: none;
  }
  .inputKeywords .b-form-tag{
    background-color: hsl(94deg 51% 73%);
    border-radius: 5px;
    font-size: 1em;
    display: flex;
    align-items: center !important;
  }
  .formselect, .forminput, .inputKeywords{
    border-color: #eee;
    color: #555;
    width: 100%;
    padding: 5px 15px;
  }
  .inputKeywords .b-form-tag-remove{
    font-size: 3em;
    margin-top: -0.2em;
    background-color: transparent;
    border: none;
  }
  #__BVID__55___removed_tags__ {
    display: none !important;
  }
  #__BVID__47___removed_tags__ {
    display: none !important;
  }
  .v-select .vs__selected-options {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .v-select ul *{
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .emoji {
    font-size: 25px !important;
  }
  .select-caret {
    stroke: #000;
    stroke-width: 1.5;
    width: 0.8em;
  }
  .v-select {
    --vs-actions-padding: 4px 4px 0 3px;
  }
  .vs__dropdown-toggle {
    padding: 0 0 2px !important;
  }
  input::placeholder {
    color: #8e8e8e !important;
  }
  @media (max-width: 576px) {
    .smaller-text {
      font-size: 0.6em !important;
    }
    .navbar-text{
      font-size: 0.75em !important;
      margin-left: -28px;
    }
  }
</style>

<style scoped>
  .sidebarcol{
    background-color: hsl(244deg 21% 13%);
    overflow: hidden;
    max-width: 10em;
    width: 100%;
  }
  #content{
    flex-grow:9;
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 0.85em;
    background-color: hsl(230deg 30% 96%);
  }
  .content,.contentattendance{
    background-color: hsl(230deg 30% 96%);
    padding: 1em;
  }
  .contentattendance{
    background-color: hsl(250deg 70% 96%);
    padding: 0;
  }
  .contentfullscreen{
    padding: 1em;
    /* padding-top: 4.5em; */
    /* overflow-x: hidden; */
  }
  .contentfullscreen>div{
    padding: 0;
  }
  body *::-webkit-scrollbar{
    width: .75em;
  }
  body *::-webkit-scrollbar-track{
    background: hsl(228deg 20% 95%);
  }
  body *::-webkit-scrollbar-thumb{
    background-color: hsl(244deg 26% 80%);
  }
</style>